import { createRouter, createWebHistory } from 'vue-router'
import Login from '../views/Login.vue';
// import Consultation from '../views/Consultation.vue';
import TestFile from '../views/TestFile.vue';
const routes = [
  {
    path: '/',
    name: 'default',
    component: Login,
    meta: {
      reload: true,
    },
  },
  {
    path: '/login',
    name: 'login',
    component: Login,
    meta: {
      reload: true,
    },
  },
  {
    path: '/test',
    name: 'TestFile',
    component: TestFile,
    meta: {
      reload: true,
    },
  },
  {
    path: '/admin/extension',
    name: 'AssignExtension',
    component: () => import(/* webpackChunkName: "about" */ '../views/admin/AssignExtension.vue'),
    meta: {
      allowAnonymous: true,
    }
  },
  {
    path: '/admin/settings',
    name: 'Setting',
    component: () => import(/* webpackChunkName: "about" */ '../views/admin/Setting.vue'),
    meta: {
      allowAnonymous: true,
    }
  },
  {
    path: '/admin/dashboard',
    name: 'Dashboard',
    component: () => import(/* webpackChunkName: "about" */ '../views/admin/Dashboard.vue'),
    meta: {
      allowAnonymous: true,
    }
  },
  {
    path: '/admin/profile',
    name: 'AdminProfile',
    component: () => import(/* webpackChunkName: "about" */ '../views/admin/AdminProfile.vue'),
    meta: {
      allowAnonymous: true,
    }
  },
  // {
  //   path: '/user/dashboard',
  //   name: 'UserDashboard',
  //   component: () => import(/* webpackChunkName: "about" */ '../views/user/UserDashboard.vue'),
  //   meta: {
  //     allowAnonymous: true,
  //   }
  // },
  {
    path: '/user/dashboard',
    name: 'UserDashboard',
    component: () => import(/* webpackChunkName: "about" */ '../views/user/VIA.vue'),
    meta: {
      // allowAnonymous: true,
    }
  },
  {
    path: '/user/profile',
    name: 'Profile',
    component: () => import(/* webpackChunkName: "about" */ '../views/user/Profile.vue'),
    meta: {
      allowAnonymous: true,
    }
  },
  {
    path: '/user/lead/edit/:uid',
    name: 'UserLeadEdit',
    component: () => import(/* webpackChunkName: "about" */ '../views/user/LeadEdit.vue'),
    meta: {
      allowAnonymous: true,
    }
  },
  {
    path: '/user/create',
    name: 'UserCreate',
    component: () => import(/* webpackChunkName: "about" */ '../views/admin/UserCreate.vue'),
    meta: {
      allowAnonymous: true,
    }
  },
  {
    path: '/user/records',
    name: 'UserRecords',
    component: () => import(/* webpackChunkName: "about" */ '../views/admin/UserRecords.vue'),
    meta: {
      allowAnonymous: true,
    }
  },
  {
    path: '/user/edit/:uid',
    name: 'UserEdit',
    component: () => import(/* webpackChunkName: "about" */ '../views/admin/UserEdit.vue'),
    meta: {
      allowAnonymous: true,
    }
  },
  {
    path: '/manager/dashboard',
    name: 'ManagerDashboard',
    component: () => import(/* webpackChunkName: "about" */ '../views/manager/ManagerDashboard.vue'),
    meta: {
      allowAnonymous: true,
    }
  },
  {
    path: '/manager/profile',
    name: 'ManagerProfile',
    component: () => import(/* webpackChunkName: "about" */ '../views/manager/ManagerProfile.vue'),
    meta: {
      allowAnonymous: true,
    }
  },
  {
    path: '/manager/lead/records',
    name: 'ManagerLeadRecords',
    component: () => import(/* webpackChunkName: "about" */ '../views/manager/LeadRecords.vue'),
    meta: {
      allowAnonymous: true,
    }
  },
  {
    path: '/manager/lead/create',
    name: 'ManagerLeadCreate',
    component: () => import(/* webpackChunkName: "about" */ '../views/manager/LeadCreate.vue'),
    meta: {
      allowAnonymous: true,
    }
  },
  {
    path: '/manager/lead/edit/:uid',
    name: 'ManagerLeadEdit',
    component: () => import(/* webpackChunkName: "about" */ '../views/manager/LeadEdit.vue'),
    meta: {
      allowAnonymous: true,
    }
  },

  {
    path: '/manager/create',
    name: 'ManagerCreate',
    component: () => import(/* webpackChunkName: "about" */ '../views/admin/ManagerCreate.vue'),
    meta: {
      allowAnonymous: true,
    }
  },
  {
    path: '/manager/records',
    name: 'ManagerRecords',
    component: () => import(/* webpackChunkName: "about" */ '../views/admin/ManagerRecords.vue'),
    meta: {
      allowAnonymous: true,
    }
  },
  {
    path: '/manager/edit/:uid',
    name: 'ManagerEdit',
    component: () => import(/* webpackChunkName: "about" */ '../views/admin/ManagerEdit.vue'),
    meta: {
      allowAnonymous: true,
    }
  },
  {
    path: '/lead/create',
    name: 'LeadCreate',
    component: () => import(/* webpackChunkName: "about" */ '../views/admin/LeadCreate.vue'),
    meta: {
      allowAnonymous: true,
    }
  },
  {
    path: '/lead/records',
    name: 'LeadRecords',
    component: () => import(/* webpackChunkName: "about" */ '../views/admin/LeadRecords.vue'),
    meta: {
      allowAnonymous: true,
    }
  },
  {
    path: '/lead/edit/:id',
    name: 'LeadEdit',
    component: () => import(/* webpackChunkName: "about" */ '../views/admin/LeadEdit.vue'),
    meta: {
      allowAnonymous: true,
    }
  },


  // {
  //   path: '/enrollment/formedit/:uid',
  //   name: 'EnrollmentFormEdit',
  //   component: () => import(/* webpackChunkName: "about" */ '../views/enrollment/agent/EnrollmentFormEdit.vue'),
  //   meta: {
  //     allowAnonymous: true,
  //   }
  // }
 ]

 const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
