<template>
  <div class="profile-dropdown">
        <div data-bs-toggle="dropdown" aria-expanded="false" class="pr-name">
          <span class="profile-img"><i class="bi bi-person-circle"></i></span>
          <span class="us-name">{{userName}}</span>
        </div>
        <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
        <li><router-link :to="{ name:profileUrl  }" class="dropdown-item" style="cursor: pointer"><i class="bi bi-person"></i> Profile</router-link></li>
        <li><a class="dropdown-item" style="cursor: pointer" v-on:click="userLogout"><i class="bi bi-box-arrow-right"></i> Logout</a></li>
    </ul>        
   </div>
</template>
<script>
import callService from "@/services/Service";
const { getData, getByID, postData } = callService();
export default {
  name: "Common",
  data() {
    return {
      userName: JSON.parse(localStorage.getItem("LogIn")).user_fname,
      profileUrl: null,
    };
  },

  mounted() {
    if (localStorage.getItem("LogIn")) {
      const type_uid = JSON.parse(localStorage.getItem("LogIn")).type_uid;
      const redirction =
        type_uid === 1
          ? "AdminProfile"
          : type_uid === 2
          ? "ManagerProfile"
          : type_uid === 3
          ? "Profile"
          : "/login";
      this.profileUrl = redirction;
    }
  },
  methods: {
    userLogout() {
      localStorage.clear();
      this.$router.push({ name: "login" });
      //this.updateLogoutStatus();
    },
    async updateLogoutStatus() {
      const user_uid = JSON.parse(localStorage.getItem("LogIn")).user_uid;
      try {
        const resData = await postData("login/updateLoginStatus", {
          user_uid: user_uid,
          login_status: 0,
        });
        if (resData.status === 200 && resData.data.statuscode === 1) {
          localStorage.clear();
          this.$router
            .push({
              name: "login",
            })
            .then(() => {
              this.$router.go();
            });
        }
      } catch (e) {
        this.error = e;
      }
    },
  },
};
</script>


