<template>
    <div>
      <textarea v-model="text" placeholder="Enter text to convert to speech"></textarea>
      <button @click="convertTextToSpeech">Convert to Speech</button>
      <audio v-if="audioSrc" :src="audioSrc" controls></audio>
    </div>
  </template>
  <script>
  import axios from 'axios';  
  export default {
    data() {
      return {
        text: '',
        audioSrc: ''
      };
    },
    methods: {
      async convertTextToSpeech() {
        try {
          // Your Deepgram API key (replace with actual key)
          const apiKey = '1a34b81962cb9d58014ed0d280bf0ae7706555b5';
          // API request payload
          const payload = {
            text: this.text // Adjust based on actual API requirements
          };
  
          // Make POST request to Deepgram TTS API
          const response = await axios.post(
            'https://api.deepgram.com/v1/text-to-speech', // Replace with actual endpoint
            payload,
            {
              headers: {
                'Authorization': `Bearer ${apiKey}`,
                'Content-Type': 'application/json'
              }
            }
          );
          // Decode the base64 audio content and create a Blob URL
          const audioContent = response.data.audioContent; // Adjust based on actual response
          const audioBlob = new Blob([Uint8Array.from(atob(audioContent), c => c.charCodeAt(0))], { type: 'audio/mp3' });
          this.audioSrc = URL.createObjectURL(audioBlob);
        } catch (error) {
          console.error('Error converting text to speech:', error);
        }
      }
    }
  };
  </script>
  