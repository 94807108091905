import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
// import { VueSignaturePad } from "vue-signature-pad";
// import CKEditor from '@mayasabha/ckeditor4-vue3';
//createApp(App).use(router).mount('#app')
// createApp(App).use(CKEditor).use(router).mount('#app')
//createApp(App).use(router).mount('#app')
// const app = createApp(App);
//import VueCookies from 'vue-cookies'; 
// import { createHead } from '@vueuse/head'
// const head = createHead()
createApp(App).use(router).mount("#app");
// app.component("VueSignaturePad", VueSignaturePad);
// Bootstrap 5
import "bootstrap/dist/js/bootstrap.js"
import $ from "jquery";
import "datatables"

const timeoutInMS = 1800000; // 30 minutes -> 30 * 60 * 1000
let timeoutId;
  
function handleInactive() {
  // Here you want to logout a user and/or ping your token  
  localStorage.clear();  
  window.location.href = "/";
}

function startTimer() { 
    // setTimeout returns an ID (can be used to start or clear a timer)
    timeoutId = setTimeout(handleInactive, timeoutInMS);
}

function resetTimer() { 
    clearTimeout(timeoutId);
    startTimer();    
}
 
function setupTimers () {
    document.addEventListener("keypress", resetTimer, false);
    document.addEventListener("mousemove", resetTimer, false);
    document.addEventListener("mousedown", resetTimer, false);
    document.addEventListener("touchmove", resetTimer, false);
     
    startTimer();
}

setupTimers();